import { DataTable } from "mantine-datatable";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";
import ModalDefault from "../../modalDefault";
import {
  ApiCreateMantenimientoCase,
  ApiDescargaFile,
} from "../../../api/formularios";
import { PieChart } from "react-minimal-pie-chart";
import { Button, MultiSelect, Tabs, rem } from "@mantine/core";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ApiGetAllUsers } from "../../../api/usuarios";
import { ApiCrearFormularioCompartido } from "../../../api/formularioCompartido";
import UserStatisticsChart from "./UserStatisticsChart";
import dayjs from "dayjs";
//import DynamicBarChart from "./BarChart";
//import { ApiDownloadedStaticFilesTemp } from "../../../api/files";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import MyCustomMP4Renderer from "../../customVideo";
import MantenimientoTable from "./mantenimiento";

const animatedComponents = makeAnimated();

const TablaOpciones = (props) => {
  const state = props.state;
  const setState = props.setState;
  const [modalMantenimiento, setModalMantenimiento] = useState(false);
  const [mantenimientoCase, setMantenimientoCase] = useState([]);
  const [modalReporte, setModalReporte] = useState(false);
  const [infoSeletedReporte, setInfoSelectedReporte] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [modalShare, setModalShare] = useState(false);
  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersFilter, setUsersFilter] = useState([]); //filtro de usuarios
  const [columns, setColumns] = useState([]);
  const [records, setRecords] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const [xxxx, setx] = useState([]);
  const [areas, setAreas] = useState([]);
  const [pestanaUsuarios, setPestanaUsuarios] = useState(false);
  //filtros
  const [selectedAreas, setSelectedAreas] = useState([]);
  //ordenamiento
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "",
    direction: "desc",
  });

  //archivos
  const [urlDocument, setUrlDocument] = useState([]);
  const [staticFilesTemp, setStaticFilesTemp] = useState([]);

  useEffect(() => {
    buildColumns();
    generateChats();
    init();
    buildRecords();
    setIsInit(true);
  }, [props.preguntas]);

  const init = async () => {
    const { data } = await ApiGetAllUsers();
    let filtered = data.filter((item) => item.estado !== false);
    const usuarios = filtered.map((item) => {
      return {
        value: item.pre_usuario_id,
        label: `${item.usuario} - ${item.nombres} ${item.apellidos}`,
      };
    });
    setSelectedUsers(state.pre_usuarios);
    setUsersOptions(usuarios);

    ////////////////////////////////////////////////
    const UserCount = props.usersRecords.reduce((acumulador, actual) => {
      if (actual.pre_usuarios) {
        // Usuario logeado
        const existente = acumulador.find(
          (item) => item.cedula === actual.cedula && item.logeado === "Si"
        );

        if (existente) {
          existente.fichas += 1;
          existente.pre_fichas.push({
            pre_ficha_id: actual.pre_ficha_id,
            created_at: actual.created_at,
          });
        } else {
          acumulador.push({
            cedula: actual.cedula,
            nombres: actual.pre_usuarios.nombres,
            apellidos: actual.pre_usuarios.apellidos,
            rol: actual.pre_usuarios.pre_roles.rol,
            area: actual.pre_usuarios.pre_areas.pre_area,
            pre_fichas: [
              {
                pre_ficha_id: actual.pre_ficha_id,
                created_at: actual.created_at,
              },
            ],
            logeado: "Si",
            fichas: 1,
          });
        }
      } else {
        // Usuario no logeado
        const existente = acumulador.find(
          (item) => item.cedula === actual.cedula && item.logeado === "No"
        );

        if (existente) {
          existente.fichas += 1;
          existente.pre_fichas.push({
            pre_ficha_id: actual.pre_ficha_id,
            created_at: actual.created_at,
          });
        } else {
          acumulador.push({
            cedula: actual.cedula,
            nombres: actual.nombres,
            pre_fichas: [
              {
                pre_ficha_id: actual.pre_ficha_id,
                created_at: actual.created_at,
              },
            ],
            apellidos: "",
            rol: "",
            area: "Sin área",
            logeado: "No",
            fichas: 1,
          });
        }
      }

      return acumulador;
    }, []);

    let areaspp = [];

    UserCount.forEach((user) => {
      if (!areaspp.includes(user.area)) {
        areaspp.push(user.area);
      }
    });

    setUsers(UserCount);
    setUsersFilter(UserCount);
    setAreas([...areaspp]);
  };

  const buildColumns = async () => {
    if (props.preguntas.length === 0) return;
    const columnasAdicionales = [
      {
        accessor: "-4",
        title: "Op.",
        textAlignment: "center",
        width: 60,
        sortable: true,
      },
      {
        accessor: "-3",
        title: "ID.",
        textAlignment: "center",
        width: 60,
        sortable: true,
      },
      {
        accessor: "-2",
        title: "Fecha",
        textAlignment: "center",
        width: 120,
        sortable: true,
      },
      {
        accessor: "-1",
        title: "Cedula",
        textAlignment: "center",
        width: "auto",
      },
      {
        accessor: "0",
        title: "Nombres y Apellidos",
        textAlignment: "center",
        width: "auto",
        sortable: true,
      },
    ];
    /*     console.log("======================================");
    console.log(props.preguntas, "preguntas");
    console.log("======================================");
 */
    const columnasPreguntas = await props.preguntas.map((pregunta) => {
      return {
        accessor: `${pregunta.pre_pregunta_id}`,
        title: pregunta.pregunta,
        textAlignment: "center",
        noWrap: false,
        width: getWith(pregunta),
        tipo: pregunta.pre_tipos_opciones.tipo,
      };
    });
    setColumns([...columnasAdicionales, ...columnasPreguntas]);
  };

  const getWith = (pregunta) => {
    return pregunta.pre_tipos_opciones.tipo === "date"
      ? 90
      : pregunta.pre_tipos_opciones.tipo === "time"
      ? 50
      : pregunta.pre_tipos_opciones.tipo === "select" ||
        pregunta.pre_tipos_opciones.tipo === "radio" ||
        pregunta.pre_tipos_opciones.tipo === "checkbox"
      ? //obtener el ancho de la opcion mas larga
        pregunta.pre_opciones.reduce((acumulador, actual) => {
          return acumulador > actual.opcion.length
            ? acumulador
            : actual.opcion.length;
        }, 0) * 10
      : pregunta.pre_tipos_opciones.tipo === "file"
      ? 150
      : "auto";
  };

  const mapearpreguntaopciones = (res) => {
    let r =
      res.respuesta === ""
        ? [""]
        : JSON.parse(res.respuesta).map((record) =>
            record.selected === true ? record.opcion : ""
          );

    if (
      !(
        res.observacion === null ||
        res.observacion === undefined ||
        res.observacion === ""
      )
    ) {
      r.push(` || Observación: ${res.observacion}`);
    }

    return r;
  };

  const onDowload = async () => {
    for (const fileUrl of urlDocument) {
      const { sms, url } = await ApiDescargaFile({ file: fileUrl.file });
      if (sms === "ok") {
        window.open(url, "_blank");
      }
    }
    onClosedModal();
  };

  const mapearpreguntasFiles = (res) => {
    function toLinks(links) {
      let r = "";
      links.forEach((link) => {
        r += `${link.file} , `;
      });
      return r;
    }

    return res.respuesta === "" ? (
      "Sin archivos"
    ) : (
      <>
        <button
          name={toLinks(JSON.parse(res.respuesta))}
          onClick={(e) => {
            setShowModal(true);
            setUrlDocument(JSON.parse(res.respuesta));
            buildLinks(JSON.parse(res.respuesta));
          }}
          className="bg-btn-vet"
        >
          {`Ver (${JSON.parse(res.respuesta).length} archivo${
            JSON.parse(res.respuesta).length > 1 ? "s" : ""
          })`}
        </button>
        {!(
          res.observacion === "" ||
          res.observacion === null ||
          res.observacion === undefined
        ) && <p className="text-table-sm">Observación: {res.observacion}</p>}
      </>
    );
  };

  const buildLinks = async (links) => {
    let linksTemp = [];
    for (const fileUrl of links) {
      const { sms, url, fileType, fileName } = await ApiDescargaFile({
        file: fileUrl.file,
      });
      if (sms === "ok") {
        linksTemp.push({
          uri: url,
          fileName,
          fileType,
        });
      }
    }
    setStaticFilesTemp(linksTemp);
  };

  /*
  useEffect(() => {
    if (urlDocument.length === 0) {
      setStaticFilesTemp([]);
    }

    if (urlDocument.length > 0) {
      onDownloadStaticFilesTemp(urlDocument);
    }
  }, [urlDocument]);

  const onDownloadStaticFilesTemp = async (files) => {
    const res = await ApiDownloadedStaticFilesTemp(files);
    if (res.data.sms === "ok") {
      setStaticFilesTemp(res.data.data);
    } else {
      toast.error(res.data.mensaje, {
        position: "bottom-left",
      });
    }
  };
*/

  const valida = ["text", "date", "time"];
  let data = [];
  data = props.usersRecords.map((fila) => {
    const newData = fila.pre_fichas_preguntas.map((res) => {
      let resp = valida.includes(res.pre_preguntas.pre_tipos_opciones.tipo)
        ? `${res.respuesta} ${
            res.observacion === "" ||
            res.observacion === null ||
            res.observacion === undefined
              ? ""
              : " || Observación: " + res.observacion
          }`
        : res.pre_preguntas.pre_tipos_opciones.tipo === "file"
        ? mapearpreguntasFiles(res)
        : mapearpreguntaopciones(res);

      const obj = {
        pre_ficha_id: fila.pre_ficha_id,
        pre_pregunta: res.pre_preguntas.pregunta,
        [res.pre_pregunta_id]: resp,
        orden: res.pre_preguntas.orden,
      };
      return obj;
    });

    newData.push({
      pre_ficha_id: fila.pre_ficha_id,
      "-2": dayjs(fila.created_at).format("YYYY-MM-DD HH:mm"),
      orden: -2,
    });

    newData.push({
      pre_ficha_id: fila.pre_ficha_id,
      "-1": fila.cedula,
      orden: -1,
    });
    newData.push({
      pre_ficha_id: fila.pre_ficha_id,
      0: fila.nombres,
      orden: 0,
    });
    newData.push({
      pre_ficha_id: fila.pre_ficha_id,
      "-3": fila.pre_ficha_id,
      orden: -3,
      id: true,
    });

    newData.push({
      pre_ficha_id: fila.pre_ficha_id,
      "-4": (
        <button
          style={{ background: "#f6dfda",borderRadius: "5px" }}
          onClick={() => {
            //generateMantenimientoCaseFn(fila)
            //setMantenimientoCase((prev) => [...prev, fila]);
            setModalReporte(true);
            setInfoSelectedReporte(fila);
          }}
        >
          <i className="fas fa-eye"></i>
        </button>
      ),
      orden: -4,
    });

    return newData;
  });

  //ordenar data por fecha por fecha de creacion utilizando dayjs
  data.sort((a, b) => {
    const fechaA = a.find((item) => item.orden === -2)["-2"];
    const fechaB = b.find((item) => item.orden === -2)["-2"];
    return dayjs(fechaA).isBefore(fechaB) ? 1 : -1;
  });

  const generateMantenimientoCaseFn = async (data) => {
    const res = await ApiCreateMantenimientoCase(data);
    if (res.sms === "ok") {
      alert(res.mensaje);
    } else {
      alert(res.mensaje);
    }
    console.log(res, "res desde mantenimiento");
  };

  const buildRecords = async () => {
    const build = [];
    data.forEach((subarray) => {
      const mergedObject = {};
      subarray.forEach((item) => {
        if (item) {
          const key = Object.keys(item).find(
            (k) => k !== "pre_ficha_id" && k !== "orden"
          );
          const value = item[key];
          mergedObject[key] = value;
        }
      });
      build.push(mergedObject);
    });
    setRecords(build);
  };

  const generateChats = async () => {
    const idsPreguntas = props.preguntas
      .filter(
        (data) =>
          data.pre_tipos_opciones.tipo === "select" ||
          data.pre_tipos_opciones.tipo === "radio"
      )
      .map((data) => {
        return {
          pre_pregunta_id: data.pre_pregunta_id,
          pregunta: data.pregunta,
        };
      });

    const re = [];
    for (let i = 0; i < idsPreguntas.length; i++) {
      const aux = [];
      data.forEach((subarray) => {
        const ramdomColor = Math.floor(Math.random() * 16777215).toString(16);
        const values = subarray.find(
          (item) => item[idsPreguntas[i].pre_pregunta_id] !== undefined
        )[idsPreguntas[i].pre_pregunta_id];

        if (values.length === 1 && values[0] === "") {
          values[0] = "Vacio";
        }

        const valuesFiltered = values.filter(
          (value) => value !== "" && value.split("||").length === 1
        );
        valuesFiltered.forEach((value) => {
          const existente = aux.find((item) => item.title === value);
          if (existente) {
            existente.value += 1;
          } else {
            aux.push({
              title: value,
              color: `#${ramdomColor}`,
              key: value,
              title: value,
              value: 1,
            });
          }
        });
      });

      if (aux.length === 0) continue;

      re.push({
        pregunta: idsPreguntas[i].pregunta,
        chart: aux,
      });
    }
    setx(re);
  };

  const sharedAccess = () => {
    setModalShare(true);
  };

  const handleShareAccess = async () => {
    setModalShare(false);

    const res = await ApiCrearFormularioCompartido({
      pre_formulario_id: state.pre_formulario_id,
      pre_usuarios: state.pre_usuarios,
    });

    if (res.sms === "err") {
      toast.error(res.mensaje, {
        position: "bottom-left",
      });
      return;
    }

    const users = [];

    res.data.forEach((item) => {
      users.push(item.pre_usuario_id);
    });

    toast.success(res.mensaje, {
      position: "bottom-left",
    });

    //reset state
    setState({
      ...state,
      pre_usuarios: users,
      compartidos: res.data,
    });
  };

  const exportToExcelReporte = () => {
    const workbook = XLSX.utils.book_new();
    const dataWithHeaders = records.map((record) => {
      const newRecord = {};
      columns.forEach((column) => {
        if (column.tipo === "file") {
          let value = record[column.accessor];
          if (value === "Sin archivos") {
            newRecord[column.title] = value;
          } else {
            newRecord[column.title] = value.props.name;
          }
        } else if (["radio", "select", "checkbox"].includes(column.tipo)) {
          const values = record[column.accessor]?.filter(
            (value) => value !== ""
          );
          newRecord[column.title] = values.join(", ");
        } else {
          newRecord[column.title] = record[column.accessor];
        }
      });
      return newRecord;
    });

    const worksheet = XLSX.utils.json_to_sheet(dataWithHeaders);

    // Ajustar el ancho de las columnas
    const colWidths = columns.map((column) => {
      return Math.max(
        ...dataWithHeaders.map((row) => row[column.title]?.length || 0),
        column.title.length
      );
    });

    worksheet["!cols"] = colWidths.map((w) => ({ wch: w }));

    XLSX.utils.book_append_sheet(workbook, worksheet, "TablaDatos");
    XLSX.writeFile(workbook, `${state.formulario.formulario}.xlsx`);
    toast.success("Tabla exportada a Excel correctamente");
  };

  const exportToExcelUsuarios = () => {
    const exportUsers = usersFilter.map((user) => {
      return {
        cedula: user.cedula,
        nombres: user.nombres,
        apellidos: user.apellidos,
        rol: user.rol,
        logeado: user.logeado,
        area: user.area,
        fichas: user.fichas,
      };
    });

    const workSheet = XLSX.utils.json_to_sheet(exportUsers);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Reporte Usuarios");
    XLSX.writeFile(workBook, `Usuarios - ${state.formulario.formulario} .xlsx`);
  };

  const onClosedModal = () => {
    setShowModal(false);
    setUrlDocument([]);
    setStaticFilesTemp([]);
  };

  const columnsUsuarios = [
    {
      accessor: "cedula",
      title: "Cedula",
      textAlignment: "center",
      width: "auto",
    },
    {
      accessor: "cedula",
      title: "Cedula",
      textAlignment: "center",
      width: "auto",
    },
    {
      accessor: "nombres",
      title: "Nombres",
      textAlignment: "center",
      width: "auto",
    },
    {
      accessor: "apellidos",
      title: "Apellidos",
      textAlignment: "center",
      width: "auto",
    },
    {
      accessor: "rol",
      title: "Rol",
      textAlignment: "center",
      width: "auto",
    },
    {
      accessor: "area",
      title: "Area",
      textAlignment: "center",
      width: "auto",
      filter: (
        <MultiSelect
          data={areas.map((area) => area)}
          value={selectedAreas}
          onChange={setSelectedAreas}
          placeholder="Filtrar por área..."
        />
      ),
      filtering: selectedAreas.length > 0,
    },
    {
      accessor: "fichas",
      title: "Cantidad de fichas",
      textAlignment: "center",
      width: "auto",
    },
    {
      accessor: "logeado",
      title: "Logeado",
      textAlignment: "center",
      width: "auto",
    },
  ];

  //logica para manejar los filtros
  useEffect(() => {
    const selectedAreasSet = new Set(selectedAreas);

    const isAreaSelected = (user) =>
      selectedAreas.length === 0 || selectedAreasSet.has(user.area);

    const filteredUsers = users.filter((user) => {
      return isAreaSelected(user);
    });

    setUsersFilter(filteredUsers);
  }, [selectedAreas]);

  //logica para manejar el ordenamiento de fechas en records
  useEffect(() => {
    if (!isInit) return;
    if (sortStatus.columnAccessor === "-2") {
      const sortedRecords = [...records].sort((a, b) => {
        const fechaA = a["-2"];
        const fechaB = b["-2"];
        return dayjs(fechaA).isBefore(fechaB) ? 1 : -1;
      });

      if (sortStatus.direction === "desc") {
        setRecords(sortedRecords.reverse());
      } else {
        setRecords(sortedRecords);
      }
    }

    if (sortStatus.columnAccessor === "0") {
      const sortedRecords = [...records].sort((a, b) => {
        const nombreA = a["0"].toLowerCase();
        const nombreB = b["0"].toLowerCase();
        return nombreA.localeCompare(nombreB);
      });

      if (sortStatus.direction === "desc") {
        setRecords(sortedRecords.reverse());
      } else {
        setRecords(sortedRecords);
      }
    }
  }, [sortStatus]);

  return (
    <div className="row">
      <div className="col-md-12 mb-2 d-flex justify-content-between w-100 align-items-center">
        <div>
          {" "}
          {!props.verCompartidos && (
            <button
              onClick={sharedAccess}
              type=""
              className="bg-btn-vet bordesombreado my-2"
              style={{ marginRight: "1rem" }}
            >
              <i className="fas fa-share-alt mx-2"></i>
              Compartir acceso
            </button>
          )}
          <button
            type=""
            className="bg-btn-vet bordesombreado my-2"
            onClick={
              pestanaUsuarios
                ? () => exportToExcelUsuarios()
                : () => exportToExcelReporte()
            }
          >
            <i className="fas fa-file-excel mx-2"></i>
            Exportar a Excel
          </button>
        </div>
        <label>Records ({records.length})</label>
      </div>
      <Tabs defaultValue="fichas">
        <Tabs.List>
          <Tabs.Tab
            value="fichas"
            onClick={() => {
              setPestanaUsuarios(false);
            }}
          >
            <h5>
              <i className="fas fa-chart-pie"></i> Fichas
            </h5>
          </Tabs.Tab>
          <Tabs.Tab
            value="usuarios"
            onClick={() => {
              setPestanaUsuarios(true);
            }}
          >
            <h5>
              <i className="fas fa-users"></i> Usuarios
            </h5>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="fichas">
          <div
            className="col-md-12 mt-2"
            style={{
              maxHeight: "350px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <DataTable
              borderRadius="md"
              className="bordecompleto"
              highlightOnHover
              noRecordsText="No se encontraron datos"
              records={records}
              columns={columns}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              totalRecords={records.length}
              withColumnBorders
            />
          </div>
          <div className="col-md-12">
            {mantenimientoCase.length > 0 ? (
              <div className="w-100" style={{ height: "500px" }}>
                <MantenimientoTable data={mantenimientoCase} />
              </div>
            ) : null}
          </div>
          <div className="w-100 d-flex justify-content-center py-3">
            <div className="col-md-12">
              {xxxx.length > 0
                ? xxxx.map((data) => {
                    return (
                      <div
                        className="w-100 bordecompleto my-4"
                        key={data.pregunta}
                      >
                        <p>Pregunta: {data.pregunta}</p>
                        <div className="d-flex justify-content-around w-100">
                          <div className="d-flex justify-content-center">
                            {data.chart.length > 0 && (
                              <PieChart
                                className="w-100"
                                label={({ dataEntry }) =>
                                  `${Math.round(dataEntry.percentage)} %`
                                }
                                data={data.chart}
                                key={data.pregunta}
                              ></PieChart>
                            )}
                            {/*
                            {data.chart.length > 0 && data.chart.length < 10 ? (
                              <PieChart
                                className="w-100"
                                label={({ dataEntry }) =>
                                  `${Math.round(dataEntry.percentage)} %`
                                }
                                data={data.chart}
                                key={data.pregunta}
                              ></PieChart>
                            ) : (
                              <div style={{
                                width: "100%",
                                height: "100%",
                              }}>
                                <DynamicBarChart
                                  key={data.pregunta}
                                  data={data}
                                />
                              </div>
                            )}
                            */}
                          </div>
                          <div>
                            {data.chart.length > 0 ? (
                              <table key={data.pregunta}>
                                <tbody>
                                  {data.chart.map((dat) => {
                                    return (
                                      <tr key={dat.title}>
                                        <td>
                                          <label
                                            htmlFor=""
                                            style={{
                                              background: dat.color,
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          ></label>
                                        </td>
                                        <td>
                                          {`(${dat.value}) ${Math.round(
                                            (dat.value / records.length) * 100
                                          )}%`}
                                        </td>
                                        <td>{dat.title}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="usuarios">
          <div
            className="col-md-12 mt-2"
            style={{
              maxHeight: "350px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <DataTable
              borderRadius="md"
              className="bordecompleto"
              highlightOnHover
              noRecordsText="No se encontraron datos"
              records={usersFilter}
              columns={columnsUsuarios}
              totalRecords={usersFilter.length}
              withColumnBorders
            />
          </div>
          <div
            className="w-100 d-flex justify-content-center aling-items-center py-3"
            style={{
              height: "400px",
              margin: "0 auto",
            }}
          >
            <UserStatisticsChart userData={usersFilter} />
          </div>
        </Tabs.Panel>
      </Tabs>
      <ModalDefault
        bandera={"archivos"}
        modal={showModal}
        size={"lg"}
        icontitulo={<null />}
        titulo={`Archivos`}
        closeAction={onClosedModal}
      >
        <div className="row justify-content-center text-align-center ">
          {staticFilesTemp && staticFilesTemp.length > 0 ? (
            <DocViewer
              pluginRenderers={[...DocViewerRenderers, MyCustomMP4Renderer]}
              documents={staticFilesTemp}
              style={{
                height: "400px",
              }}
              language="es"
            />
          ) : (
            <p>No hay archivos</p>
          )}
        </div>
        <div className="d-flex justify-content-end flex-row align-self-start my-4">
          <button
            type=""
            onClick={onClosedModal}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cancelar
          </button>
          <button
            type=""
            onClick={onDowload}
            className="bg-btn-vet sombra mx-1"
          >
            <i className="fas fa-download mx-2"></i> Descargar todo
          </button>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"compartir"}
        modal={modalShare}
        size={"md"}
        icontitulo={<null />}
        titulo={`Compartir acceso`}
        closeAction={() => {
          setModalShare(false);
        }}
      >
        <div className="row pb-4 ">
          <div className="col-md-12">
            <label className="py-2 negrita">Usuarios</label>
            <Select
              value={usersOptions.filter((option) =>
                selectedUsers.includes(option.value)
              )}
              isMulti
              name="pre_usuarios"
              options={usersOptions}
              placeholder="Seleccione los usuarios para el grupo"
              onChange={(selectedOptions) => {
                const values = selectedOptions.map((option) => option.value);
                setState({
                  ...state,
                  pre_usuarios: values,
                });
                setSelectedUsers(values);
              }}
              closeMenuOnSelect={false}
              components={animatedComponents}
              className="basic-multi-select react-select-container"
              classNamePrefix=" select react-select"
            />
          </div>
          <Button
            onClick={handleShareAccess}
            className="mt-4"
            leftIcon={<i className="fas fa-share"></i>}
          >
            Compartir
          </Button>
        </div>
      </ModalDefault>

      <ModalDefault
        bandera={"compartir"}
        modal={modalMantenimiento}
        size={"md"}
        icontitulo={<null />}
        titulo={`Compartir acceso`}
        closeAction={() => {
          setModalMantenimiento(false);
        }}
      >
        <div className="row pb-4 ">
          <div className="col-md-12"></div>
          <Button
            onClick={handleShareAccess}
            className="mt-4"
            leftIcon={<i className="fas fa-share"></i>}
          >
            Compartir
          </Button>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"reportemantenimiento"}
        modal={modalReporte}
        size={"lg"}
        icontitulo={<null />}
        titulo={`Detalle del reporte`}
        closeAction={() => {
          setModalReporte(false);
        }}
      >
        <div className="row pb-4 ">
          <div className="col-md-12">
            {infoSeletedReporte ? (
              <div className="w-100">
                <div>
                  <div>
                    <strong>QUIEN REPORTA: </strong>
                    {infoSeletedReporte.nombres} <strong>CON CÉDULA: </strong>
                    {infoSeletedReporte.cedula}
                  </div>

                  <div>
                    <strong>FECHA DEL REPORTE :</strong>
                    <label>
                      {dayjs(infoSeletedReporte.created_at).format(
                        "YYYY-MM-DD"
                      )}
                    </label>
                  </div>
                </div>
                <hr />
                {infoSeletedReporte.pre_fichas_preguntas.length > 0 ? (
                  infoSeletedReporte.pre_fichas_preguntas.map((data) => {
                    const valida = ["text", "date", "time"];

                    let resp = valida.includes(
                      data.pre_preguntas.pre_tipos_opciones.tipo
                    )
                      ? `${data.respuesta} ${
                          data.observacion === "" ||
                          data.observacion === null ||
                          data.observacion === undefined
                            ? ""
                            : " || Observación: " + data.observacion
                        }`
                      : data.pre_preguntas.pre_tipos_opciones.tipo === "file"
                      ? mapearpreguntasFiles(data)
                      : mapearpreguntaopciones(data);
                    return (
                      <div className="w-100">
                        <strong className="w-100">
                         {data.pre_preguntas.orden})  {data.pre_preguntas.pregunta} 
                        </strong>
                        <p>{resp}</p>
                      </div>
                    );
                  })
                ) : (
                  <div>No exiten preguntas</div>
                )}
              </div>
            ) : (
              "No hay información que se pueda mostrar"
            )}
          </div>
        </div>
      </ModalDefault>
    </div>
  );
};

export default TablaOpciones;

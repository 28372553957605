import React, { useEffect } from 'react';

const users = [
  { id: 1, name: "John Doe", email: "john@example.com", role: "Admin", lastLogin: "2024-01-09 10:30 AM" },
  { id: 2, name: "Jane Smith", email: "jane@example.com", role: "User", lastLogin: "2024-01-08 03:45 PM" },
  { id: 3, name: "Bob Johnson", email: "bob@example.com", role: "Editor", lastLogin: "2024-01-09 09:15 AM" },
  { id: 4, name: "Alice Brown", email: "alice@example.com", role: "User", lastLogin: "2024-01-07 11:20 AM" },
  { id: 5, name: "Charlie Davis", email: "charlie@example.com", role: "Moderator", lastLogin: "2024-01-09 08:00 AM" },
];

const MantenimientoTableaux = ({data}) => {
    useEffect(()=>{
        console.log(data)
    },[])
  return (
    <div className="container mt-5">
      <h2 className="mb-4">Fichas que se reportarán a mantenimiento.</h2>
      <table className="table table-striped table-hover">
        <thead className="table-dark">
          <tr>
            <th>ID</th>
            <th>DATA</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.length>0?data.map((data,index) => (
            <tr key={index}>
              <td>{data.pre_ficha_id}</td>
            </tr>
          )):null}
        </tbody>
      </table>
    </div>
  );
};

export default MantenimientoTableaux;
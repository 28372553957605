import { useEffect, useState } from "react";
import { ApiGetMantenimientoCase } from "../../../api/formularios";
import MantenimientoTable from "../../../components/admin/mantenimientos/listarMantenimientos";

const Mantenimientos = () => {
  const [mantenimientos, setMantenimientos] = useState([]);

  const getAllMantenimientos = async () => {
    const res = await ApiGetMantenimientoCase();
    console.log(res);
    if (res.sms === "ok") {
      setMantenimientos(res.data);
    }
  };

  useEffect(() => {
    getAllMantenimientos();
  }, []);
  return (
    <div>
      <MantenimientoTable isAdmin={true} data={mantenimientos} />
    </div>
  );
};

export default Mantenimientos;

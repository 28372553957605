import React, { useEffect, useState } from "react";
import ModalDefault from "../../modalDefault";
import dayjs from "dayjs";
import { ApiDescargaFile } from "../../../api/formularios";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import MyCustomMP4Renderer from "../../customVideo";

const MantenimientoTable = ({ data }) => {
  const [modalReporte, setModalReporte] = useState(false);
  const [infoSeletedReporte, setInfoSelectedReporte] = useState(null);
     const [urlDocument, setUrlDocument] = useState([]);
     const [staticFilesTemp, setStaticFilesTemp] = useState([]);
       const [showModal, setShowModal] = useState(false);
     
  useEffect(() => {
    console.log(data);
  }, []);

  const mapearpreguntaopciones = (res) => {
    let r =
      res.respuesta === ""
        ? [""]
        : JSON.parse(res.respuesta).map((record) =>
            record.selected === true ? record.opcion : ""
          );

    if (
      !(
        res.observacion === null ||
        res.observacion === undefined ||
        res.observacion === ""
      )
    ) {
      r.push(` || Observación: ${res.observacion}`);
    }

    return r;
  };

  const onDowload = async () => {
    for (const fileUrl of urlDocument) {
      const { sms, url } = await ApiDescargaFile({ file: fileUrl.file });
      if (sms === "ok") {
        window.open(url, "_blank");
      }
    }
  };
    const buildLinks = async (links) => {
      let linksTemp = [];
      for (const fileUrl of links) {
        const { sms, url, fileType, fileName } = await ApiDescargaFile({
          file: fileUrl.file,
        });
        if (sms === "ok") {
          linksTemp.push({
            uri: url,
            fileName,
            fileType,
          });
        }
      }
      setStaticFilesTemp(linksTemp);
    };

  const mapearpreguntasFiles = (res) => {
    function toLinks(links) {
      let r = "";
      links.forEach((link) => {
        r += `${link.file} , `;
      });
      return r;
    }

    return res.respuesta === "" ? (
      "Sin archivos"
    ) : (
      <>
        <button
          name={toLinks(JSON.parse(res.respuesta))}
          onClick={(e) => {
            setShowModal(true)
            setUrlDocument(JSON.parse(res.respuesta));
            buildLinks(JSON.parse(res.respuesta));
          }}
          className="bg-btn-vet"
        >
          {`Ver (${JSON.parse(res.respuesta).length} archivo${
            JSON.parse(res.respuesta).length > 1 ? "s" : ""
          })`}
        </button>
        {!(
          res.observacion === "" ||
          res.observacion === null ||
          res.observacion === undefined
        ) && <p className="text-table-sm">Observación: {res.observacion}</p>}
      </>
    );
  };

  const onClosedModal = () => {
    setShowModal(false);
    setUrlDocument([]);
    setStaticFilesTemp([]);
  };
  return (
    <div className="container mt-5">
      <h2 className="mb-4">ACCIONES INSEGURAS REPORTADAS</h2>
      <table className="table table-striped table-hover">
        <thead className="table-dark">
          <tr>
            <th>ID</th>
            <th>DATA</th>
            <th>TECNICO</th>
            <th>PRIORIDAD</th>
            <th>ESTADO</th>
            <th>NUMERO O/T</th>
            <th>COSTO</th>
            <th>AÑO EJECUTADO</th>
            <th>OBSERVACIÓN</th>
            <th>ACTIVO</th>
            <th>ACCIONES</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0
            ? data.map((data, index) => (
                <tr key={index}>
                  <td>{data.inforeporte.pre_ficha_id}</td>
                  <td>
                    <button
                      onClick={() => {
                        console.log(data.inforeporte);
                        setModalReporte(true);
                        setInfoSelectedReporte(data.inforeporte);
                      }}
                    >
                      Ver reporte
                    </button>
                  </td>
                  <td>{data.tecnico ? data.tecnico : "NO DEFINIDO"}</td>

                  <td>{data.prioridad ? data.prioridad : "NO DEFINIDO"}</td>
                  <td>{data.estado ? data.estado : "NO DEFINIDO"}</td>
                  <td>{data.numero_ot ? data.numero_ot : "NO ASIGNADO"}</td>
                  <td>{data.costo ? data.costo : "NO ASIGNADO"}</td>
                  <td>
                    {data.anio_ejecutado ? data.anio_ejecutado : "NO ASIGNADO"}
                  </td>
                  <td>{data.observacion ? data.observacion : "NO ASIGNADO"}</td>
                  <td>{data.activo ? "SI" : "NO"}</td>
                  <td>...</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      <ModalDefault
        bandera={"reportemantenimiento"}
        modal={modalReporte}
        size={"lg"}
        icontitulo={<null />}
        titulo={`Detalle del reporte`}
        closeAction={() => {
          setModalReporte(false);
        }}
      >
        <div className="row pb-4 ">
          <div className="col-md-12">
            {infoSeletedReporte ? (
              <div className="w-100">
                <div>
                  <div>
                    <strong>QUIEN REPORTA: </strong>
                    {infoSeletedReporte.nombres} <strong>CON CÉDULA: </strong>
                    {infoSeletedReporte.cedula}
                  </div>

                  <div>
                    <strong>FECHA DEL REPORTE :</strong>
                    <label>
                      {dayjs(infoSeletedReporte.created_at).format(
                        "YYYY-MM-DD"
                      )}
                    </label>
                  </div>
                </div>
                <hr />
                {infoSeletedReporte.pre_fichas_preguntas.length > 0 ? (
                  infoSeletedReporte.pre_fichas_preguntas.map((data) => {
                    const valida = ["text", "date", "time"];

                    let resp = valida.includes(
                      data.pre_preguntas.pre_tipos_opciones.tipo
                    )
                      ? `${data.respuesta} ${
                          data.observacion === "" ||
                          data.observacion === null ||
                          data.observacion === undefined
                            ? ""
                            : " || Observación: " + data.observacion
                        }`
                      : data.pre_preguntas.pre_tipos_opciones.tipo === "file"
                      ? mapearpreguntasFiles(data)
                      : mapearpreguntaopciones(data);
                    return (
                      <div className="w-100">
                        <strong className="w-100">
                          {data.pre_preguntas.pregunta}
                        </strong>
                        <p>{resp}</p>
                      </div>
                    );
                  })
                ) : (
                  <div>No exiten preguntas</div>
                )}
              </div>
            ) : (
              "No hay información que se pueda mostrar"
            )}
          </div>
        </div>
      </ModalDefault>
      <ModalDefault
        bandera={"archivos"}
        modal={showModal}
        size={"lg"}
        icontitulo={<null />}
        titulo={`Archivos`}
        closeAction={onClosedModal}
      >
        <div className="row justify-content-center text-align-center ">
          {staticFilesTemp && staticFilesTemp.length > 0 ? (
            <DocViewer
              pluginRenderers={[...DocViewerRenderers, MyCustomMP4Renderer]}
              documents={staticFilesTemp}
              style={{
                height: "400px",
              }}
              language="es"
            />
          ) : (
            <p>No hay archivos</p>
          )}
        </div>
        <div className="d-flex justify-content-end flex-row align-self-start my-4">
          <button
            type=""
            onClick={onClosedModal}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cancelar
          </button>
          <button
            type=""
            onClick={onDowload}
            className="bg-btn-vet sombra mx-1"
          >
            <i className="fas fa-download mx-2"></i> Descargar todo
          </button>
        </div>
      </ModalDefault>
    </div>
  );
};

export default MantenimientoTable;
